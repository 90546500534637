import { useState, useEffect, useContext, Fragment } from 'react';
import ConfigContext from '../../../context/ConfigContext';
import AuthContext from '../../../context/AuthContext';
import { cleanValue, getValueOrDefault } from '../../../helpers/utility';
import { Button, TextField } from '@mui/material';

import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { userSignin } from '../../../actions/user.actions';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { AzureMsalCreateConfig } from '../../../helpers/azure';
import ButtonAD from './ButtonAD';

export const RenderButtonAzureAD = (msalApplication=null, tenant=null, app=null, test=false) => {
  return !msalApplication || !tenant || !app? (<></>): (
    tenant.b2c === 'N'? (
      <MsalProvider instance={msalApplication}>
        <div className="d-flex jc-center mt-2">
          <ButtonAD msalApplication={msalApplication} tenant={tenant} app={app} test={test} />
        </div>
      </MsalProvider>
    ): (<></>)
  );
}

function SigninPage({ test=false, ...props }) {
  const dispatch = useDispatch();
  const { isValidAzureTenants, AzureTenants } = useContext(ConfigContext);
  const { onSignin } = useContext(AuthContext);

  const [values, setValues] = useState({ Email: '', Password: '' });
  const onValueChange = (key, val, isNumber=false) => {
    setValues({ ...values, [key]: cleanValue(val, isNumber) });
  }
  
  const onSigninProcess = async (e) => {
    e.preventDefault();
    const res = await userSignin(values, test)(dispatch);
    onValueChange('Password', '');
    if(res?.accessToken && res?.refreshToken && res?.user){
      onSignin({
        aToken: res.accessToken,
        rToken: res.refreshToken,
        u: res.user,
        p: res.permissions,
      });
    }
  }

  const [msalInstances, setMsalInstances] = useState({});
  /* eslint-disable */
  useEffect(() => {
    const onLoad = () => {
      try {
        if(!isValidAzureTenants) return false;

        let _msalInstances = {};
        AzureTenants.Tenants.tenants.forEach(tenant => {
          const _apps = tenant.apps;
          if(_apps){
            _apps.forEach(app => {
              const _msalConfig = AzureMsalCreateConfig(tenant, app);
              _msalInstances = {
                ..._msalInstances,
                [app.client_id]: new PublicClientApplication(_msalConfig),
              };
            });
          }
        });
        setMsalInstances(_msalInstances);
      } catch (_) { console.log(_) }
      return true;
    }
    onLoad();
  }, [isValidAzureTenants, AzureTenants]);
  /* eslint-enable */

  return (
    <section className="auth-01">
      <div className="auth-bg" style={{ backgroundImage: `url('/assets/img/bg/05.jpg')` }}></div>
      <div className="auth-filter"></div>
      <div className="auth-container bshadow">
        <div className="img-bg fit fill" style={{ backgroundImage: `url('/assets/img/bg/pattern-01.jpg')` }}></div>
        <div className="wrapper">
          <div className="d-flex jc-center">
            <div className="logo">
              <img className="ptt" src="/assets/logo.png" alt="Logo" />
              <div className="slogan">
                <div className="title">GSP</div>
                <div className="desc">PHOTO HUB</div>
              </div>
              <img className="hero hide-tablet" src="/assets/hero-01.png" alt="Hero" />
            </div>
          </div>
          <div className="auth-box pt-6">
            {isValidAzureTenants? (
              AzureTenants.Tenants.tenants.map((tenant, i) => {
                const apps = tenant.apps;
                return (
                  <Fragment key={`tenant_${i}`}>
                    {apps && apps.length? (
                      apps.map((app, j) => (
                        <Fragment key={`app_${i}_${j}`}>
                          {RenderButtonAzureAD(msalInstances[app.client_id], tenant, app, test)}
                        </Fragment>
                      ))
                    ): (<></>)}
                  </Fragment>
                );
              })
            ): (
              <form onSubmit={onSigninProcess} data-testid="form_auth">
                <div className="grids">
                  <div className="grid sm-100">
                    <TextField label="อีเมลผู้ใช้" variant="outlined" 
                      required={true} className="w-full" type="email" 
                      value={getValueOrDefault(values?.Email)} placeholder="xxxxx@pttplc.com" 
                      onChange={e => onValueChange('Email', e.target.value)} 
                    />
                  </div>
                  <div className="grid sm-100">
                    <TextField label="รหัสผ่าน" variant="outlined" 
                      required={true} className="w-full" type="password" 
                      value={getValueOrDefault(values?.Password)} placeholder="xxxxxxx" 
                      onChange={e => onValueChange('Password', e.target.value)} 
                    />
                  </div>
                  <div className="grid sm-100">
                    <Button type="submit" 
                      variant="contained" color="primary" disableElevation 
                      size="large" className="w-full" 
                    >
                      เข้าสู่ระบบ
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

SigninPage.propTypes = {
  test: PropTypes.bool,
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SigninPage);